<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader
            :title="headercontent.title"
            :addlink="headercontent.addlink"
          />
        </v-col>

        <v-col md="12">
          <h3 class="filtertitle">Filter {{ headercontent.title }}</h3>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="form-section"
          >
            <v-row>
              <v-col cols="3">
                <v-select
                  :items="activelist"
                  v-model="status"
                  label="Active / Deactive"
                  outlined
                  item-text="name"
                  item-value="status"
                ></v-select>
              </v-col>
              <v-col cols="12" class="submitbtn">
                <v-btn
                  :disabled="!valid"
                  class="submitbutton btn-dark"
                  @click="validate"
                >
                  Filter {{ headercontent.title }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col md="12">
          <v-simple-table class="table">
            <template v-slot:default v-if="allTrainings.results">
              <thead>
                <tr>
                  <th class="text-left">S/N</th>
                  <th class="text-left">Image</th>
                  <th class="text-left">Title</th>
                  <th class="text-left">Description</th>
                  <th class="text-left">Start Time</th>
                  <th class="text-left">End Time</th>
                  <th class="text-left">Status</th>
                  <th class="text-right" colspan="2">Action</th>
                </tr>
              </thead>
              <tbody v-if="!allloadingTraining">
                <tr
                  v-for="(item, index) in allTrainings.results.list"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    <img
                      :src="'https://app.necojobs.com.np' + item.imageUrl"
                      alt=""
                      class="imageold"
                    />
                  </td>
                  <td>{{ item.trainingTitle }}</td>
                  <td><div v-html="item.description.slice(0, 100)"></div></td>
                  <td>{{ item.startDate | formatDate }}</td>
                  <td>{{ item.endDate | formatDate }}</td>

                  <td>
                    <span
                      class="deactive"
                      v-if="!item.status"
                      @click="jobStatus(item)"
                      >Deactive</span
                    >
                    <span
                      v-if="item.status"
                      class="active"
                      @click="jobStatus(item)"
                      >Active</span
                    >
                  </td>
                  <td class="text-right">
                    <router-link :to="'/dashboard/training/add/' + item._id"
                      ><v-icon class="icons-action">
                        mdi-pencil
                      </v-icon></router-link
                    >
                    <router-link :to="'/dashboard/training/view/' + item._id"
                      ><v-icon class="icons-action">
                        mdi-eye
                      </v-icon></router-link
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div v-if="allloadingTraining">
            <v-skeleton-loader type="image" height="200"></v-skeleton-loader>
            <v-skeleton-loader type="image" height="200"></v-skeleton-loader>
          </div>
          <ul
            class="pagination-button"
            v-if="allTrainings.results != undefined"
          >
            <li>
              Page :
              <span v-if="allTrainings.results.pagination.next">{{
                (allTrainings.results.pagination.next - 1) * this.limit
              }}</span
              ><span v-if="!allTrainings.results.pagination.next">{{
                allTrainings.results.pagination.total
              }}</span>
              -
              {{ allTrainings.results.pagination.total }}
            </li>
            <li>
              <div>
                <v-btn
                  v-if="allTrainings.results.pagination.previous"
                  @click="prevPage(allTrainings.results.pagination.previous)"
                  class="btn-dark"
                  >Prev</v-btn
                >

                <v-btn
                  v-if="allTrainings.results.pagination.next"
                  @click="nextPage(allTrainings.results.pagination.next)"
                  class="btn-dark"
                  >Next</v-btn
                >
              </div>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Training",
  components: { PageHeader },
  data: () => ({
    headercontent: {
      title: "Trainings List",
      addlink: "/dashboard/training/add",
    },
    title: "",
    adsposition: "",
    valid: true,
    limit: 100,
    skip: 1,
    activelist: [
      { name: "Active", status: true },
      { name: "Deactive", status: false },
    ],
    status: true,
  }),
  computed: {
    ...mapGetters(["allTrainings", "allloadingTraining"]),
  },
  methods: {
    ...mapActions([
      "getTrainingListing",
      "updateTrainingStatus",
      "filterListing",
    ]),
    jobStatus(item) {
      this.updateTrainingStatus(item._id);
    },
    validate() {
      this.filterListing({
        limit: this.limit,
        skip: this.skip,
        status: this.status,
      });
    },
    nextPage(data) {
      this.filterListing({
        limit: this.limit,
        skip: data,
        status: this.status,
      });
    },
    prevPage(data) {
      this.filterListing({
        limit: this.limit,
        skip: data,
        status: this.status,
      });
    },
  },
  created() {
    this.getTrainingListing({
      limit: this.limit,
      skip: this.skip,
      status: this.status,
    });
    this.filterListing({
      limit: this.limit,
      skip: this.skip,
      status: this.status,
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.form-section {
  padding: 15px 0;
  .col {
    padding: 0 10px;
  }
}
.filtertitle {
  margin-bottom: 10px;
}
.btn-dark {
  background: $tagcolor !important;
  color: $white;
  margin-top: -10px;
}
img {
  width: 100px;
}

.table {
  box-shadow: 0px -1px 5px 2px rgba(0, 0, 0, 0.1);

  thead {
    border-bottom: 2px solid $menucolor;
    background: $tagcolor;
  }
  th {
    font-size: $normal_lfont !important;
    color: $white !important;
  }
}
.deactive,
.active {
  display: block;
  padding: 2px;
  width: 100px;
  text-align: center;
  color: $white;
  border-radius: 20px;
}
.deactive {
  background: red;
}
.active {
  background: green;
}
.active {
  background: green;
}
.pagination-button {
  display: flex;
  list-style: none;
  padding-left: 0;
  justify-content: space-between;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
  .btn-dark {
    margin-top: 0;
    margin-left: 10px;
  }
}
.table-heading,
.pagination-button {
  border: 1px solid $menucolor;
  margin-bottom: 0;
  padding: 10px;
  align-items: center;
}
.icons-action {
  margin: 10px;
  font-size: $iconfont;
}
</style>
